<template>
  <div class="gateway-page x-background-color x-box-shadow">
    <x-table
      new-style
      :no-data-text="CA('gateway_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    >
      <template #footer>
        <div class="footer">
          <Page
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            @on-change="pageChange"
          ></Page>
        </div>
      </template>
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="一体机" prop="aioGuid">
          <Select v-model="form.aioGuid" clearable style="width: 300px">
            <Option
              v-for="(item, index) in YTJArr"
              :value="item.guid"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="设备名称" prop="name">
          <Input v-model="form.name" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="设备key" prop="deviceKey">
          <Input v-model="form.deviceKey" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="服务器地址" prop="serverIp">
          <Input v-model="form.serverIp" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="服务器端口" prop="serverPort">
          <Input v-model="form.serverPort" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="连接方式" prop="linkMode">
          <Select v-model="form.linkMode" style="width: 300px">
            <Option :value="1">udp</Option>
            <Option :value="2">tcp</Option>
            <Option :value="3">阿里云</Option>
          </Select>
        </FormItem>
        <FormItem label="连接数量" prop="doCount">
          <Input v-model="form.doCount" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="主动上报" prop="isFictitious">
          <Select v-model="form.isFictitious" style="width: 300px">
            <Option :value="1">是</Option>
            <Option :value="0">否</Option>
          </Select>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <look-detail v-model="lookDetailShow" :detail="detail"></look-detail>

    <heart-beat
      v-model="heartBeatShow"
      :oldGuid="oldGuid"
      :oldName="oldName"
      @update-success="updateSuccess"
    ></heart-beat>
  </div>
</template>

<script>
import { Switch } from "view-design";
import lookDetail from "./lookDetail";
import heartBeat from "./heartbeat";
export default {
  name: "",
  components: {
    lookDetail,
    heartBeat,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "网关名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "服务IP",
            width: 150,
            key: "serverIp",
          },
          {
            title: "服务端口",
            width: 100,
            key: "serverPort",
          },
          {
            title: "连接方式",
            width: 100,
            render: (h, { row }) => {
              return <span>{row.linkMode === 1 ? "udp" : "tcp"}</span>;
            },
          },
          {
            title: "主动上报",
            width: 100,
            render: (h, { row }) => {
              return <span>{row.isFictitious === 0 ? "否" : "是"}</span>;
            },
          },
          {
            title: "连接IP",
            width: 150,
            key: "udpIp",
          },
          {
            title: "连接端口",
            width: 100,
            key: "udpPort",
          },
          {
            title: "连接状态",
            width: 100,
            key: "clientStatusName",
          },
          {
            title: "更新时间",
            width: 180,
            key: "updateTime",
          },
          {
            title: "网关状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <Switch
                  size="large"
                  true-value={1}
                  false-value={
                    +row.status == "0" ? 0 : +row.status == "2" ? 2 : ""
                  }
                  value={+row.status}
                  disabled={
                    +row.status == 1
                      ? this.CA("gateway_stop")
                        ? false
                        : true
                      : this.CA("gateway_open")
                      ? false
                      : true
                  }
                  loading={this.statusLoading}
                  onInput={(val) => this.statusChange(val, row.guid)}
                >
                  <span slot="open">启用</span>
                  <span slot="close">停用</span>
                </Switch>
              );
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("gateway_edit") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("gateway_view") && (
                    <a
                      class="iconfont"
                      title="查看详情"
                      style="margin-right: 10px"
                      onClick={() => this.look(row)}
                    >
                      &#xe66d;
                    </a>
                  )}
                  {this.CA("gateway_heart") && (
                    <a
                      class="iconfont"
                      title="设备心跳"
                      style="margin-right: 10px"
                      onClick={() => this.configHeartBeat(row)}
                    >
                      &#xe659;
                    </a>
                  )}
                  {this.CA("gateway_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.guid)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "添加网关",
            ca: "device_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "网关名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },

        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      statusLoading: false,

      form: {
        aioGuid: "",
        name: "",
        deviceKey: "",
        linkMode: "",
        doCount: "",
        isFictitious: "",
        remark: "",
        serverIp: "",
        serverPort: "",
        id: "",
      },

      rules: {
        aioGuid: [{ required: true, message: "请选择一体机" }],
        name: [{ required: true, message: "请填写设备名称" }],
        deviceKey: [{ required: true, message: "请填写设备key" }],
        doCount: [{ required: true, message: "请填写连接数量" }],
        isFictitious: [{ required: true, message: "请选择是否主动上报" }],
        linkMode: [{ required: true, message: "请选择连接方式" }],
        serverIp: [{ required: true, message: "请填写服务器地址" }],
        serverPort: [{ required: true, message: "请填写服务器端口" }],
      },

      YTJArr: [],

      search_data: {
        aioGuid: "",
      },

      lookDetailShow: false,
      detail: {},

      heartBeatShow: false,
      oldGuid: "",
      oldName: "",
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "添加网关",
        submitLoading: false,
      };
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    //获取网关列表
    getList() {
      if (!this.CA("gateway_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BUSSDEVICELUOLA.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //获取一体机列表
    getYtjList() {
      this.$post(this.$api.DEVICES.LIST, {
        pageNo: 1,
        pageSize: 999,
      }).then((res) => {
        this.YTJArr = res.list;
      });
    },
    //开启和关闭
    statusChange(status, guid) {
      this.statusLoading = true;
      this.$post(
        status == "1"
          ? this.$api.BUSSDEVICELUOLA.OPEN
          : this.$api.BUSSDEVICELUOLA.STOP,
        {
          guid,
        }
      )
        .then(() => {
          this.$Message.success(status == "1" ? "启用成功" : "停用成功");
          this.getList();
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.guid = row.guid;
      this.form.id = row.id;
      this.modal = {
        show: true,
        title: "编辑网关",
        submitLoading: false,
      };
    },
    del(guid) {
      this.$post(this.$api.BUSSDEVICELUOLA.DELETE, {
        guid,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    look(row) {
      this.lookDetailShow = true;
      this.detail = row;
    },
    configHeartBeat(row) {
      this.heartBeatShow = true;
      this.oldGuid = row.guid;
      this.oldName = row.name;
    },
    updateSuccess() {
      this.heartBeatShow = false;
      this.getList();
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        aioGuid: "",
        name: "",
        deviceKey: "",
        linkMode: "",
        doCount: "",
        isFictitious: "",
        remark: "",
        serverIp: "",
        serverPort: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.modal.submitLoading = true;
        this.$post(
          this.form.id
            ? this.$api.BUSSDEVICELUOLA.UPDATE
            : this.$api.BUSSDEVICELUOLA.ADD,
          {
            ...this.form,
          }
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getYtjList();
  },
};
</script>

<style lang="less" scoped>
.gateway-page {
  width: 100%;
  height: 100%;
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      span {
        padding-right: 16px;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 16px;
  }
}
</style>