<template>
  <div>
    <Modal
      :value="value"
      title="查看详情"
      @input="(val) => $emit('input', val)"
      footer-hide
      class="detail-modal"
    >
      <div class="detail-modal-content">
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">网关名称：</span>
          <span>{{ detail.name }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">guid：</span>
          <span>{{ detail.guid }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">服务IP：</span>
          <span>{{ detail.serverIp }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">服务端口：</span>
          <span>{{ detail.serverPort }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接方式：</span>
          <span>{{ detail.linkMode === 1 ? "udp" : "tcp" }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">主动上报：</span>
          <span>{{ detail.isFictitious === 0 ? "否" : "是" }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接IP：</span>
          <span>{{ detail.udpIp }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接端口：</span>
          <span>{{ detail.udpPort }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接状态：</span>
          <span>{{ detail.clientStatusName }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">更新时间：</span>
          <span>{{ detail.updateTime }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">创建时间：</span>
          <span>{{ detail.createTime }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">备注：</span>
          <span>{{ detail.remark }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">网关状态：</span>
          <span>{{ detail.statusName }}</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.detail-modal {
  .detail-modal-content {
    .detail-modal-item {
      margin-bottom: 10px;
      display: flex;
      .detail-modal-item-label {
        display: inline-block;
        width: 110px;
        text-align: right;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }
}
</style>